.unit-page-layout-v1 {
	margin-top: 60px;

	.inner-banner {
		background-image: url(RESOURCE/img/search-banner.jpg);
		height: 449px;

		@media (max-width: 1199px) {
			height: 14.032rem;
		}

		.page-title {
			text-align: left;

			.pag-title {
				padding: 0;
				border: none;

				@media (max-width: 1199px) {
					display: none !important;
				}
			}
		}
	}

	.page-header-color-row {
		background-color: #343218;
		margin-bottom: 2.25rem;

		.color {
			height: 0.938rem;
		}

		.color-1 {
			background-color: #062845;
		}

		.color-2 {
			background-color: #f38624;
		}

		.color-3 {
			background-color: #c84e0b;
		}
	}

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}