.search-page-layout-v3 {
	background-color: var(--color-white);
	margin-top: 35px;

	.inner-banner {
		background-image: url(RESOURCE/img/search-banner.jpg);
		height: 449px;

		@media (max-width: 1199px) {
			height: 14.032rem;
		}

		.page-title {
			text-align: left;

			.pag-title {
				padding: 0;
				border: none;

				@media (max-width: 1199px) {
					display: none !important;
				}
			}
		}
	}

	.page-header-color-row {
		background-color: #343218;
		margin-bottom: 2.25rem;

		.color {
			height: 0.938rem;
		}

		.color-1 {
			background-color: #062845;
		}

		.color-2 {
			background-color: #f38624;
		}

		.color-3 {
			background-color: #c84e0b;
		}
	}

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {
		@media (min-width:1200px) {
			/*max-width: 1370px; */
		}

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}