@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff') format('woff');
	font-display: swap;
}

/* poppins-regular - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff') format('woff');
	font-display: swap;
}

/* poppins-500 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff') format('woff');
	font-display: swap;
}

/* poppins-600 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff') format('woff');
	font-display: swap;
}

/* poppins-700 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff') format('woff');
	font-display: swap;
}

/* barlow-semi-condensed-300 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-600 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-700 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* parisienne-regular - latin-ext_latin */
@font-face {
	font-family: 'Parisienne';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/parisienne-v12-latin-ext_latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/parisienne-v12-latin-ext_latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-SemiBold.eot');
	src: url('RESOURCE/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Regular.eot');
	src: url('RESOURCE/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Regular.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Bold.eot');
	src: url('RESOURCE/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Bold.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Light.eot');
	src: url('RESOURCE/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Light.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Medium.eot');
	src: url('RESOURCE/fonts/OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Medium.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/OpenSans-Medium.svg#OpenSans-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Black.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Black.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Black.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Black.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Black.svg#SourceSansPro-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLight.eot');
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Light.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Light.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-SemiBold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}